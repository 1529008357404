import somethingWentWrong from 'assets/images/something_went_wrong.png';
import { useEffect } from 'react';
import chunkFileVersioningHandler from 'Utils/chunkFileVersioningHandler';
import './style.css';

const FallbackPage = (props) => {
  useEffect(() => {
    chunkFileVersioningHandler(props);
  }, []);

  function handleClick() {
    window.location.href = '/';
  }

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <img
        src={somethingWentWrong}
        style={{
          marginTop: '2.265rem',
          width: '18vw',
          objectFit: 'contain',
        }}
        className='img-fluid error_img'
        alt=''
      />
      <h2
        style={{ fontSize: '3vw', fontWeight: 'normal', fontFamily: 'var(--font-family)' }}
        className='text-center'
      >
        Something went wrong
      </h2>
      <div className='errorbutton d-flex gap-3'>
        <button
          type='button'
          className='trybutton whiteCta'
          onClick={() => window.location.reload()}
        >
          TRY AGAIN
        </button>
        <button type='button' className='trybutton blackCta' onClick={handleClick}>
          RETURN TO HOME PAGE
        </button>
      </div>
    </div>
  );
};

export default FallbackPage;
