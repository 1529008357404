/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DeviceMobileCamera, Handbag, HeartStraight, User, SignOut } from 'phosphor-react';
import { CATEGORIES, GET_BRANDS_LIST } from 'B2B/Gql';
import Skeleton from 'Components/Global/Skeleton';
import Popup from 'Components/Login popup/Popup';
import gb_main from 'assets/images/GBook_Logo-B2C.svg';
import gb_business_logo from 'B2B/Assets/Icons/GB-Biz-Logo-png.png';
// eslint-disable-next-line import/no-cycle
import Form from 'Components/Login popup/Form';
import B2BForm from 'B2B/Components/SignInSignUp/Form/Form';
import { getCartDetails, getCustomerCartId } from 'Features/cart/cartSlice';
import './header.css';
import { resetAllOnLogout } from 'Features/global/globalAction';
import { changeB2BTrigger, changeTrigger } from 'Features/login';
import { getDropdownWishlist } from 'Features/wishlist/wishlistSlice';
import { SCHEDULER, VIRTUAL_CART_ITEM } from 'Utils/Strings';
import { ListGroup } from 'react-bootstrap';
import { notification } from 'Utils/Toast';
import { IsB2BLogged, IsB2BRoute } from 'B2B/Utils/IsB2B';
import { BANNER_SLIDER } from 'Gql/LandingPages';
import Search from './Search';
import InnerHtml from './InnerHtml';
import CartDropdown from './CartDropdown';
import WishlistDropdown from './WishlistDropdown';

function DesktopHeader({ dwhite, wishlistCount, cartCount, cart, userFirstName }) {
  const [showPopover, setShowPopover] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [pophover, setPopHover] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const navigate = useNavigate();
  const submenuRef = useRef(null);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);
  const [isUserLoggedIn, searchtIsUserLoggedIn] = useState(false);
  const url = window.location.href;
  const wishlist = useSelector((state) => state.wishlist?.dropdownWishlist);
  const profileCompletion = useSelector((state) => state.cart?.profileCompletion);
  const isB2BRouteState = IsB2BRoute() && !IsB2BLogged();
  const isHideTopNavigation = ['/shopping-cart', '/payment', '/checkout', '/voucherdetails'].some(
    (path) => pathname.includes(path),
  );

  const loginTrigger = useSelector((state) => state.login.b2bTrigger);

  const isB2BAllowedState = useSelector((state) => state?.storeConfig?.isB2BAllowed);

  //  Fetch the wishlist count and data.
  const FetchWishlistData = async () => {
    const data = {
      currentPage: 0,
      pageSize: 0,
    };
    try {
      await dispatch(getDropdownWishlist(data)).unwrap();
    } catch (error) {
      console.error('Fetching wishlist', error);
    }
  };

  useEffect(() => {
    if (showWishlist && pathname !== '/wishlist') FetchWishlistData();
  }, [showWishlist]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if (profileCompletion < 100) setIncomplete(true);
      else setIncomplete(false);
    }
  }, [pathname, profileCompletion]);

  useEffect(() => {
    setShowCart(false);
    setShowWishlist(false);
    setPopHover(false);
  }, [pathname]);

  window.addEventListener('profileChanged', () => {
    dispatch(getCustomerCartId());
  });
  // eslint-disable-next-line no-unused-vars
  const [scrollPosition, SetScrollPosition] = useState(0);

  const { data: stripData, loading: stripLoading } = useQuery(BANNER_SLIDER, {
    variables: {
      page_type: 'global',
      type: 'web',
    },
  });

  const { data, loading } = useQuery(CATEGORIES, {
    fetchPolicy: 'no-cache',
  });

  const [loadBrandsList, { data: brandData }] = useLazyQuery(GET_BRANDS_LIST, {});

  const handleMouseEnter = () => {
    loadBrandsList();
  };

  (window.onload = () => {
    const navListItem = document.querySelectorAll('.level0');
    Array.prototype.forEach.call(navListItem, (e) => {
      e.onmouseover = function () {
        document.querySelector('.backdrop').style.display = 'block';
      };
      e.onmouseleave = function () {
        document.querySelector('.backdrop').style.display = 'none';
      };
    });
  })();

  const originalBrandsHeader = [
    'Just Launched',
    'Featured Brand',
    'Exclusive brands',
    'Popular',
    'All Brands',
  ];

  const requiredBrandsHeader = [[], [], [], []];

  const handleScroll = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [showPopover]);

  const signOut = (e) => {
    setPopHover(() => false);
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('zipcode');
    dispatch(resetAllOnLogout());
    dispatchEvent(new Event('storage'));
    notification.success('Logout successfull!');

    setTimeout(() => {
      setPopHover(() => true);
    }, 400);
  };

  useEffect(() => {
    if (userFirstName) {
      searchtIsUserLoggedIn(true);
    } else {
      searchtIsUserLoggedIn(false);
    }
  }, [userFirstName]);

  // Checking monetize to navigate brand store
  const navigateTopage = (brand) => {
    if (brand.is_monetized === '1') {
      navigate({
        pathname: `/brand-store/${brand.url_key}/${brand.entity_id}`,
      });
    } else {
      navigate({
        pathname: `/product-listing/${brand.url_key}`,
      });
    }
  };

  if (loading || stripLoading)
    return (
      <div className='skeleton-header-mob'>
        <Skeleton
          style={{
            borderRadius: '0',
            background: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Skeleton
            style={{
              height: '10px',
              borderRadius: '10px',
              width: '80%',
              margin: '20px auto',
            }}
          />
        </Skeleton>
        <Skeleton
          style={{
            borderRadius: '0',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            borderBottom: 'solid 0.8px #999',
          }}
        >
          <Skeleton
            style={{
              height: '40px',
              borderRadius: '0',
              width: '300px',
              margin: '10px',
            }}
          />
        </Skeleton>
        <Skeleton
          className=''
          style={{
            height: '100px',
            borderRadius: '0',
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8].map((ele) => (
            <Skeleton
              key={ele}
              style={{
                height: '20px',
                borderRadius: '10px',
                width: '100px',
                margin: 'auto 20px',
              }}
            />
          ))}
        </Skeleton>
      </div>
    );

  const PopOver = () => (
    <div
      id='popover-basic '
      className={isUserLoggedIn ? 'hed container-fluid' : 'hed hed-sign-in-popup container-fluid'}
    >
      <div>
        <div className='pop-over'>
          <div className='pop-over-arrow' />
          <div className='pop-over-arrow-cursor-position' />
        </div>
        <ListGroup as='ul' className='pop-head'>
          {userFirstName ? (
            <ListGroup as='ul' className='signout pop-signin-li'>
              <ListGroup.Item as='li' className='txtbld border-0' action={false}>
                Hello,
                <span className='pop-user-name'>
                  {userFirstName.length > 8 ? `${userFirstName.substring(0, 8)}...` : userFirstName}
                </span>
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                role='button'
                className='signout-li whiteCta'
                onClick={() => {
                  navigate('/profile');
                  setShowPopover(false);
                  setPopHover(() => false);
                }}
                action
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onMouseLeave={() => {
                  setPopHover(() => false);
                }}
              >
                <User size={21} />
                <span className='signout-cont'>My Account</span>
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                role='button'
                className='signout-li whiteCta'
                onClick={() => {
                  navigate('/profile/order');
                  setShowPopover(false);
                  setPopHover(() => false);
                }}
                action
              >
                <Handbag size={21} />
                <span className='signout-cont'>My Orders</span>
              </ListGroup.Item>
              <ListGroup.Item
                as='li'
                role='button'
                className='signout-li whiteCta'
                onClick={signOut}
                action
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <SignOut size={21} />
                <span className='signout-cont'>Sign out</span>
              </ListGroup.Item>
            </ListGroup>
          ) : (
            <ListGroup.Item
              as='li'
              role='button'
              className='text-left pop-signout-li sout whiteCta'
              onClick={() => {
                dispatch(changeTrigger());
                setPopHover(() => false);
              }}
              action
              style={{ cursor: 'pointer' }}
            >
              <div className='sign-in-tag'>
                <Link to={url} className='dropdown-item pop-signout-link'>
                  Sign in or Register
                </Link>
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
    </div>
  );

  const categoryGrid = (subcategory) => {
    const subcategoryGrid = [[], [], [], []];
    subcategory?.children?.map((children, index) => subcategoryGrid[index % 4].push(children));
    return subcategoryGrid;
  };

  const brandCategoryGrid = (subcategory) => {
    const brandSubcategory = [[], [], [], [], []];
    subcategory?.forEach((brandSubCategory) => {
      if (brandSubCategory.is_justin === '1') {
        brandSubcategory[0].push(brandSubCategory);
      }
      if (brandSubCategory.is_featured === '1') {
        brandSubcategory[1].push(brandSubCategory);
      }
      if (brandSubCategory.is_exclusive === '1') {
        brandSubcategory[2].push(brandSubCategory);
      }
      if (brandSubCategory.is_popular === '1') {
        brandSubcategory[3].push(brandSubCategory);
      }
      brandSubcategory[4].push(brandSubCategory);
    });
    const brandSubcategoryGrid = [[], [], [], []];
    let brandIndex = 0;
    brandSubcategory.forEach((brandSub, index) => {
      if (brandSub.length) {
        requiredBrandsHeader[brandIndex]?.push(originalBrandsHeader[index]);
        brandSubcategoryGrid[brandIndex % 4]?.push(brandSub);
        brandIndex += 1;
      }
    });
    return brandSubcategoryGrid;
  };

  const SubMenu = () => (
    <ul className='d-flex align-items-center container-fluid header-con menuwidth sticky_ul mb-0 menu-border header-center'>
      {data?.categories?.items?.[0]?.children?.map(
        (category) =>
          category.include_in_menu === 1 &&
          category.display_in_top_menu === 0 && (
            <li
              key={category.id.toString()}
              className={`fullwidth parent level0 level-top ${isB2BRouteState && 'no-hover'}`}
            >
              <span
                role='button'
                tabIndex={0}
                onClick={() => {
                  setShowPopover(() => false);
                  setPopHover(() => false);
                  if (!isB2BRouteState) {
                    navigate(`/category/${category.url_path}`, {
                      state: {
                        cat_id: category.id,
                      },
                    });
                  }
                }}
              >
                {category.name}
              </span>

              {category.children.length !== 0 &&
              category.children.filter((item) => item.include_in_menu === 1).length > 0 ? (
                <div className='submenu level0'>
                  <div className='ctnir'>
                    {categoryGrid(category).map((subcategoryGrid, index) => (
                      <div className='griditem' key={index}>
                        {subcategoryGrid.map(
                          (subcategory) =>
                            subcategory.include_in_menu === 1 && (
                              <div className='item' key={subcategory.id.toString()}>
                                <h1 className='menu-head'>
                                  <div
                                    className='subcat'
                                    role='button'
                                    tabIndex={0}
                                    onClick={() => {
                                      setShowPopover(false);
                                      setPopHover(() => false);
                                      navigate(
                                        {
                                          pathname: `/product-listing/${subcategory.url_path}`,
                                        },
                                        {
                                          preventScrollReset: true,
                                        },
                                      );
                                    }}
                                  >
                                    {subcategory.name}
                                  </div>
                                  <ul className=''>
                                    {subcategory.children.map(
                                      (product, index) =>
                                        product.include_in_menu === 1 && (
                                          <li
                                            role='button'
                                            tabIndex={0}
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            key={index}
                                            onClick={() => {
                                              setShowPopover(false);
                                              setPopHover(() => false);
                                              navigate(
                                                {
                                                  pathname: `/product-listing/${product.url_path}`,
                                                },
                                                {
                                                  preventScrollReset: true,
                                                },
                                              );
                                            }}
                                          >
                                            {product.name}
                                          </li>
                                        ),
                                    )}
                                  </ul>
                                </h1>
                              </div>
                            ),
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              <div className='sticky-overlay' />
            </li>
          ),
      )}

      {/* Header Center */}
      {!isHideTopNavigation &&
        data?.categories?.items?.[0]?.children?.map(
          (category) =>
            category.include_in_menu === 1 &&
            category.display_in_top_menu === 1 && (
              <li
                key={category.id.toString()}
                className={`fullwidthtop parent level0 level-top hoverCat ${
                  isB2BRouteState && 'no-hover'
                }`}
              >
                <span
                  role='button'
                  tabIndex={0}
                  onClick={() => {
                    setShowPopover(() => false);
                    setPopHover(() => false);
                    if (!isB2BRouteState) {
                      navigate(`/category/${category.url_path}`, {
                        state: {
                          cat_id: category.id,
                        },
                      });
                    }
                  }}
                >
                  {category.name}
                </span>
              </li>
            ),
        )}
      <li
        className={`fullwidthtop parent level0 level-top hoverCat ${isB2BRouteState && 'no-hover'}`}
      >
        <div className='backdrop' />
        <span
          role='button'
          tabIndex={0}
          onClick={() => {
            if (!isB2BRouteState) {
              navigate('/brands');
            }
          }}
        >
          <span onMouseEnter={handleMouseEnter}>Brands</span>
        </span>
        <div className='submenu level0 brandsubmenu'>
          <div className='ctnir'>
            {brandData &&
              brandCategoryGrid(brandData?.getBrandsList).map((brandSubcategoryGrid, indexI) => (
                <div className='griditem' key={indexI}>
                  {brandSubcategoryGrid.map((brandSubcategory, indexJ) => (
                    <div className='item' key={indexJ}>
                      <div className='menu-head'>
                        <h1
                          className='subcat'
                          role='button'
                          tabIndex={0}
                          onClick={() => {
                            if (
                              requiredBrandsHeader[indexI][indexJ] &&
                              requiredBrandsHeader[indexI][indexJ] === 'All Brands'
                            ) {
                              navigate('/brands');
                            }
                          }}
                        >
                          {requiredBrandsHeader[indexI][indexJ]}
                        </h1>
                        {requiredBrandsHeader[indexI][indexJ] !== originalBrandsHeader[4] ? (
                          <ul className='smul'>
                            {brandSubcategory.map((subCategory, index) => (
                              <li
                                key={index}
                                role='button'
                                tabIndex={0}
                                onClick={() => navigateTopage(subCategory)}
                              >
                                {subCategory.title}
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </li>
      {(isB2BRouteState || IsB2BLogged()) && (
        <li
          className={`fullwidthtop parent level0 level-top hoverCat ${
            isB2BRouteState && 'no-hover'
          }`}
        >
          <div className='backdrop' />
          <span
            role='button'
            tabIndex={0}
            onClick={() => {
              if (!isB2BRouteState) {
                navigate('/product-listing/all-product');
              }
            }}
          >
            bulk savings
          </span>
        </li>
      )}

      {!IsB2BRoute() && !IsB2BLogged() && isB2BAllowedState && (
        <li
          className={`fullwidthtop parent level0 level-top hoverCat ${
            isB2BRouteState && 'no-hover'
          }`}
        >
          <span
            role='button'
            tabIndex={0}
            onClick={() => {
              if (!isB2BRouteState) {
                navigate(`/business`);
              }
            }}
          >
            Business
          </span>
        </li>
      )}

      {!IsB2BLogged() && !IsB2BRoute() && (
        <li
          className={`fullwidthtop parent level0 level-top hoverCat ${
            isB2BRouteState && 'no-hover'
          }`}
        >
          <span
            role='button'
            tabIndex={0}
            onClick={() => {
              if (!isB2BRouteState) {
                navigate(`/academy/samerkhouzami`);
              }
            }}
          >
            Academy
          </span>
        </li>
      )}
    </ul>
  );

  return (
    <>
      {window.innerWidth > 991 && loginTrigger && <div className='overlay b2b-landing-overlay' />}

      <nav
        id='navbar_top'
        className={`navigation sw-megamenu ${dwhite === true ? 'nav-trasparent' : null} ${
          IsB2BRoute() ? 'b2b-nav-top' : ''
        }  sticky-top d-none d-lg-block navBorder`}
      >
        <div className={IsB2BRoute() ? 'backdrop backdrop-header' : 'backdrop'} />

        <div className='header navbar-top-desktop'>
          {/* Top Header */}
          {!isHideTopNavigation && (
            <div style={{ backgroundColor: '#631011' }}>
              <div className='exclusive gbGrid'>
                {stripData?.BannerSlider.length > 0 && (
                  <InnerHtml
                    element='div'
                    className={
                      stripData?.BannerSlider.find((elm) => elm.slider_type === SCHEDULER)
                        .is_animate === 1 && 'exclusiveInner'
                    }
                    html={
                      stripData?.BannerSlider.find((elm) => elm.slider_type === SCHEDULER)
                        .banners[0].additional_information
                    }
                  />
                )}

                <div className='position-relative'>
                  {!IsB2BLogged() && IsB2BRoute() ? (
                    <Link to='/' className='getAppCta underline'>
                      GlamourBook.com
                    </Link>
                  ) : (
                    !IsB2BLogged() && (
                      <Link to='/get-app' className='getAppCta'>
                        <DeviceMobileCamera size={17} color='#FFFFFF' />
                        GET APP
                      </Link>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Main Header */}
          <header className='pb-0 nav-head'>
            <div className='header-content container-fluid header-row d-flex gbGrid'>
              {/* Header Left */}
              <div className='header-left'>
                <nav className='navbar navbar-expand-sm justify-content-between'>
                  <a href={!(IsB2BRoute() && !IsB2BLogged()) && '/'} className='brand'>
                    <img
                      src={IsB2BRoute() ? gb_business_logo : gb_main}
                      alt='GlamourBook'
                      className={IsB2BRoute() ? 'b2b_brandLogo' : 'brandLogo'}
                      width='229'
                      height='25'
                    />
                  </a>
                </nav>
              </div>

              {/* Header Right */}
              {!isHideTopNavigation && !isB2BRouteState && (
                <div className='header-right'>
                  {/* Login Flow Dropdown */}
                  <nav className='navbar navbar-expand-sm justify-content-between nvbr'>
                    <div className='collapse navbar-collapse'>
                      <Search />
                      <ul className='navbar-nav'>
                        <li
                          tabIndex={0}
                          role='menubar'
                          onMouseOver={() => setPopHover(true)}
                          onMouseLeave={() => setPopHover(false)}
                          className='user-icon nav-item dropdown h-icon dropdown-toggle'
                          aria-label='User Icon'
                        >
                          <div className={`${incomplete && isUserLoggedIn ? 'icon-wrapper' : ''}`}>
                            <User size={20} weight='light' color='#000' />
                          </div>
                          {userFirstName && !pophover && (
                            <span className='pop-user-name usrname'>
                              {userFirstName?.length > 8
                                ? `${userFirstName.substring(0, 8)}...`
                                : userFirstName}
                            </span>
                          )}
                          {pophover && <PopOver />}
                        </li>
                        {/* </OverlayTrigger> */}
                        <li
                          className='h-icon'
                          onMouseOver={() => setShowWishlist(true)}
                          onMouseLeave={() => setShowWishlist(false)}
                        >
                          <Link to='/wishlist'>
                            {wishlistCount !== 0 ? (
                              <span className='shop-icon-chip-h'>{wishlistCount}</span>
                            ) : null}
                            <HeartStraight size={20} weight='light' color='#000' />
                          </Link>
                          {wishlist.length > 0 && showWishlist && wishlistCount > 0 && (
                            <WishlistDropdown />
                          )}
                        </li>
                        <li
                          role='button'
                          className='h-icon'
                          onMouseOver={() => {
                            setShowCart(true);
                            dispatch(getCartDetails());
                          }}
                          onFocus={() => {
                            setShowCart(true);
                            dispatch(getCartDetails());
                          }}
                          onMouseLeave={() => setShowCart(false)}
                        >
                          <Link to='/shopping-cart'>
                            {cartCount !== 0 &&
                            !cart?.cart?.some((item) => item?.__typename === VIRTUAL_CART_ITEM) ? (
                              <span className='shop-icon-chip-h'>{cartCount}</span>
                            ) : null}
                            <Handbag size={20} weight='light' color='#000' />
                          </Link>
                          {showCart && localStorage.getItem('token') && cartCount > 0 && (
                            <CartDropdown />
                          )}
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              )}
              {isB2BRouteState && (
                <div className='header-right'>
                  <button
                    type='button'
                    className='blackCta b2BLoginRegister'
                    onClick={() => dispatch(changeB2BTrigger(true))}
                  >
                    LOGIN
                  </button>
                </div>
              )}
            </div>
          </header>
        </div>

        <Popup>
          <Form />
        </Popup>
        <B2BForm />
      </nav>
      {!isHideTopNavigation && (
        <div className={IsB2BRoute() && 'sub-menu-wrapper-b2b'}>
          <nav
            id='submenu'
            ref={submenuRef}
            className={`navigation click-submenu sw-megamenu ${
              dwhite === true ? 'nav-trasparent' : null
            } sticky-header d-none d-lg-block`}
          >
            <SubMenu />
          </nav>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  cartCount: state.cart.cartCount,
  cart: state.cart,
  wishlistCount: state.wishlist.wishlistCount,
  userFirstName: state.cart.userFirstName,
});
export default connect(mapStateToProps)(DesktopHeader);
