import React, { useRef, Suspense } from 'react';
import { useFirstViewportEntry } from 'Hooks/Global/useFirstViewportEntry';

const LazyOnScroll = ({
  children,
  threshold = 0,
  root = null,
  rootMargin = '0px 0px 0px 0px',
  ...wrapperDivProps
}) => {
  const ref = useRef();
  const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin });

  return (
    <div {...wrapperDivProps} ref={ref}>
      {entered && <Suspense fallback={<div>{}</div>}>{children}</Suspense>}
    </div>
  );
};

export default LazyOnScroll;
