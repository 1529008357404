const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

const chunkFileVersioningHandler = (props) => {
  if (props?.error?.name === 'ChunkLoadError') {
    const MAX_RELOADS = 3;
    const BASE_TIMEOUT = 1 * 60 * 1000;

    let reloadData = getCookie('reloadData');
    if (reloadData) {
      reloadData = JSON.parse(reloadData);
    } else {
      reloadData = { count: 0, time: Date.now(), power: 2 };
    }

    const elapsedTime = Date.now() - reloadData.time;
    const currentTimeout = BASE_TIMEOUT * reloadData.power ** 2;

    if (elapsedTime > currentTimeout) {
      reloadData.count = 1;
      reloadData.time = Date.now();
      reloadData.power += 1;
    } else if (reloadData.count < MAX_RELOADS) {
      reloadData.count += 1;
    } else {
      console.error('Maximum reload attempts reached. Avoiding further reloads.');
    }

    setCookie('reloadData', JSON.stringify(reloadData), 7);

    if (elapsedTime > currentTimeout || reloadData.count < MAX_RELOADS) {
      window.location.reload(true);
    }
  }
};

export default chunkFileVersioningHandler;
