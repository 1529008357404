import {
  addToWishlist,
  removeFromWishlist,
  updateWishlistVariants,
} from 'Features/wishlist/wishlistSlice';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const ADDED_TO_WISHLIST = 'ADDED_TO_WISHLIST';
export const REMOVED_FROM_WISHLIST = 'REMOVED_FROM_WISHLIST';
export const UPDATED_WISHLIST = 'UPDATED_WISHLIST';

const useListenAllWishlistEvents = () => {
  const dispatch = useDispatch();
  const addToWishlistEvent = useCallback(
    (event) => {
      const data = JSON.parse(event.detail || '{}');
      dispatch(
        addToWishlist({
          ...data,
        }),
      );
    },
    [dispatch],
  );

  const removeFromWishlistEvent = useCallback(
    (event) => {
      const { detail } = event;
      dispatch(removeFromWishlist(detail));
    },
    [dispatch],
  );

  const updateWishlistEvent = useCallback(
    (event) => {
      const data = JSON.parse(event.detail || '{}');
      dispatch(
        updateWishlistVariants({
          ...data,
        }),
      );
    },
    [dispatch],
  );
  useEffect(() => {
    const wishlist_add = window.addEventListener(ADDED_TO_WISHLIST, addToWishlistEvent);
    const wishlist_remove = window.addEventListener(REMOVED_FROM_WISHLIST, removeFromWishlistEvent);
    const update_wishlist = window.addEventListener(UPDATED_WISHLIST, updateWishlistEvent);
    return () => {
      window.removeEventListener(ADDED_TO_WISHLIST, wishlist_add);
      window.removeEventListener(REMOVED_FROM_WISHLIST, wishlist_remove);
      window.removeEventListener(UPDATED_WISHLIST, update_wishlist);
    };
  }, [addToWishlistEvent, removeFromWishlistEvent, updateWishlistEvent]);
};

export default useListenAllWishlistEvents;
