import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
// import OtpInput from 'react-otp-input';
import { useLazyQuery, useMutation } from '@apollo/client';
import OTPInput from 'Hooks/Otp';
import {
  CREATE_ACCOUNT_EMAIL_OTP_MUTATION,
  CREATE_ACCOUNT_EMAIL_OTP_VERIFY_MUTATION,
  CREATE_ACCOUNT_MUTATION,
  CREATE_ACCOUNT_OTP_MUTATION,
} from 'Gql/query';
import { addCodes, changeFormType } from 'Features/login';
import loginLogo from 'assets/images/GBook_Logo-B2C.svg';
import { PencilLine } from 'phosphor-react';

const VerifyOtp = ({ formvalue }) => {
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const [err, seterr] = useState('');
  const [loading, setloading] = useState(false);
  const [time, setTime] = useState(30);
  const [timeTrue, setTimeTrue] = useState(true);
  const [otp, setOtp] = useState('');

  const [CreateAccountEmailOTP] = useMutation(CREATE_ACCOUNT_EMAIL_OTP_MUTATION);
  const [CreateAccountOTP] = useMutation(CREATE_ACCOUNT_OTP_MUTATION);
  const [CreateAccountEmailOTPVerify] = useLazyQuery(CREATE_ACCOUNT_EMAIL_OTP_VERIFY_MUTATION);
  const [CreateAccountOTPVerify] = useLazyQuery(CREATE_ACCOUNT_MUTATION);

  // validators
  const validate = (otp) => {
    if (otp) {
      if (/^[0-9]*$/.test(otp)) {
        seterr('');
        setIsSubmit(() => true);
      } else {
        seterr('Enter numbers only');
        setIsSubmit(() => false);
      }
    } else {
      setIsSubmit(() => false);
      seterr('Please enter 6 digit OTP');
    }
  };

  // New function to handle Web OTP API
  const handleWebOTP = async () => {
    if ('OTPCredential' in window) {
      try {
        const abortController = new AbortController();

        // Set up OTP receiver
        const otpCredential = await navigator.credentials.get({
          otp: { transport: ['sms'] },
          signal: abortController.signal,
        });

        // If OTP is received, set it and validate
        if (otpCredential && otpCredential.code) {
          setOtp(otpCredential.code);
          validate(otpCredential.code);
        }

        // Clean up abort controller after 2 minutes
        setTimeout(() => {
          abortController.abort();
        }, 120000);
      } catch (error) {
        console.log('Error reading OTP:', error);
      }
    }
  };

  // Initialize Web OTP API when component mounts
  useEffect(() => {
    if (formvalue.type === 'phone') {
      handleWebOTP();
    }
  }, [formvalue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(() => true);
    try {
      if (formvalue.type === 'email') {
        await CreateAccountEmailOTPVerify({
          variables: { email: formvalue.field, otp, websiteId: 1 },
        }).then((result) => {
          const { createAccountEmailOTPVerify } = result.data;
          if (createAccountEmailOTPVerify.status) {
            setloading(() => false);
            dispatch(addCodes(otp));
            dispatch(changeFormType('register'));
          } else {
            setloading(() => false);
            seterr(createAccountEmailOTPVerify.message);
          }
        });
      } else {
        await CreateAccountOTPVerify({
          variables: {
            mobileNumber: formvalue.field.replace('+', ''),
            otp,
            websiteId: 1,
          },
        }).then((verfiyUser) => {
          const { createAccountOTPVerify } = verfiyUser.data;
          if (createAccountOTPVerify.status) {
            setloading(() => false);
            dispatch(addCodes(otp));
            dispatch(changeFormType('register'));
          } else {
            setloading(() => false);
            seterr(createAccountOTPVerify.message);
          }
        });
      }
    } catch (error) {
      setloading(() => false);
      seterr(() => error.message);
    }
  };

  // on change of otp
  const onChange = (otp) => {
    setOtp(() => otp);
    validate(otp);
  };

  // String Replace
  const replaceAt = function (string, start, replacement) {
    let end = string.search('@');
    if (end === -1) end = string.length - 3;
    const subString = string.substring(start, end);
    return string.replace(subString, replacement);
  };

  const resendOTP = async () => {
    setloading(true);
    if (formvalue.type === 'phone') {
      await CreateAccountOTP({
        variables: {
          mobileNumber: formvalue.field.replace('+', ''),
          websiteId: 1,
        },
      })
        .then((sendOtp) => {
          if (!sendOtp.data.hasOwnProperty('errors')) {
            setloading(() => false);
            setTime(() => 30);
            setTimeTrue(true);
            handleWebOTP();
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      await CreateAccountEmailOTP({
        variables: {
          email: formvalue.field,
          websiteId: 1,
        },
      })
        .then((sendOtp) => {
          if (!sendOtp.data.hasOwnProperty('errors')) {
            setloading(() => false);
            setTime(() => 30);
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    if (time === -1) {
      setTimeTrue(false);
      return;
    }
    const timeout = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(timeout);
  });

  return (
    <div className='login  ' data-testid='otp_verify'>
      <div className='login__form'>
        <img src={loginLogo} alt='Glamourbook' />
        <p className='subText'>
          Your beauty, your rules: Discover brands from around the world, all in one place.
        </p>
        <div className='mobConfirmation'>
          <h6>Please enter the 6 digit code sent to {replaceAt(formvalue.field, 6, '****')}</h6>
          <PencilLine
            size={16}
            color='#787878'
            onClick={() => dispatch(changeFormType('login'))}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <form className='formInputs' onSubmit={handleSubmit}>
          <div>
            <div className='d-flex align-item center flex-column'>
              <OTPInput
                autoFocus
                length={6}
                className='otp-input'
                inputClassName='otpInput'
                hasErrored={err}
                onChangeOTP={onChange}
                value={otp}
              />
              {err.length !== 0 && (
                <p className='text-validation text-start' data-testid='otp_err'>
                  {err}
                </p>
              )}
            </div>

            <button type='button' className='resend' disabled={timeTrue} onClick={resendOTP}>
              {time > -1 ? (
                <div>
                  <span className='logintime'>00:{time <= 9 ? `0${time}` : time}</span>{' '}
                  <u>Resend Code </u>
                </div>
              ) : (
                <div className='resendCode'>
                  <u className='text-dark'>Resend Code</u>
                </div>
              )}
            </button>
          </div>

          <input
            type='submit'
            className='loginCta mb-0 whiteCta'
            id='username'
            data-testid='otp-btn'
            disabled={!isSubmit}
            value={loading ? 'Loading...' : 'Verify'}
          />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formvalue: state.login.username,
});

export default connect(mapStateToProps)(VerifyOtp);
