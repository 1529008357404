import { IsB2BLogged } from 'B2B/Utils/IsB2B';

export const getProfileCompletion = (
  firstName,
  lastName,
  mobileNumber,
  emailId,
  dob,
  gender,
  emailVerified,
  b2b1,
  b2b2,
) => {
  let count = 0;
  if (firstName && (IsB2BLogged() || lastName)) {
    count += 1;
  }
  if (mobileNumber) {
    count += 1;
  }
  if (emailId) {
    count += 0.5;
  }
  if (IsB2BLogged() ? b2b2 : dob) {
    count += 1;
  }
  if (IsB2BLogged() ? b2b1 : gender) {
    count += 1;
  }
  if (emailVerified) {
    count += 0.5;
  }
  return count * 20;
};
