import { gql } from '@apollo/client';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';

export const CREATE_EMPTY_CART_ID = gql`
  mutation createEmptyCart {
    createEmptyCart
  }
`;
export const GET_CUSTOMER_CART_ID = gql`
  query {
    customerCart {
      id
    }
    customer {
      firstname
      lastname
      mobilenumber
      id
      email
      gender
      dob
      emailVerified
    }
  }
`;
export const ASSIGN_CUSTOMER_TO_GUEST_CART = gql`
  mutation assignCustomerToGuestCart($destCartID: String!) {
    assignCustomerToGuestCart(cart_id: $destCartID) {
      total_quantity
      shipping_addresses {
        selected_shipping_method {
          amount {
            value
            currency
          }
        }
      }
      amasty_rewards_used_points {
        used_points
        used_points_to_inr
      }
      available_gift_wrappings {
        design
        id
        image {
          label
          url
        }
        price {
          currency
          value
        }
        uid
      }
      reward_points_will_earn
      gift_wrapping {
        price {
          value
          currency
        }
        design
        id
      }
      items {
        uid
        prices {
          total_item_discount {
            value
          }
          price {
            value
            currency
          }
          discounts {
            amount {
              value
            }
          }
        }
        product {
          __typename
          name
          sku
          free_product_available
          offer_rules {
            discount
            type
            name
            show_on_cart
          }
          stock_status
          color
          brand_label
          product_quatity
          image {
            url
            position
          }
          thumbnail {
            url
            position
          }
          swatch_image
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            configurable_product_option_value_uid
            value_label
            swatch_data {
              type
              value
            }
          }
          configured_variant {
            sku
            name
            stock_status
            product_quatity
            thumbnail {
              url
            }
            image {
              url
              position
            }
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
        }
        quantity
        errors {
          code
          message
        }
      }
      prices {
        applied_promo_rules {
          label
          rule_id
          value
        }
        applied_coupon_code {
          total_amount
          applied_coupon_codes {
            code
            rule_id
            amount
          }
        }
        grand_total {
          value
          currency
        }
        discounts {
          amount {
            currency
            value
          }
        }
        subtotal_including_tax {
          currency
          value
        }
      }
    }
  }
`;
export const GET_CUSTOMER_CART_COUNT = gql`
  query ($id: String!) {
    cart(cart_id: $id) {
      total_quantity
    }
  }
`;

export const ADD_TO_CART_SIMPLE = gql`
  mutation addSimpleProductToCart($cartID: String!, $sku: String!, $qty: Float!) {
    addProductsToCart(cartId: $cartID, cartItems: [{ quantity: $qty, sku: $sku }]) {
      cart {
        total_quantity
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_TO_CART_WITH_QUANTITY = gql`
  mutation addSimpleProductToCart($cartID: String!, $sku: String!, $quantity: Float!) {
    addProductsToCart(cartId: $cartID, cartItems: [{ quantity: $quantity, sku: $sku }]) {
      cart {
        total_quantity
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_TO_CART_CONFIGURABLE_WITH_QUANTITY = gql`
  mutation addConfigurableToCart(
    $cartID: String!
    $sku: String!
    $selected_options: [ID!]
    $quantity: Float!
  ) {
    addProductsToCart(
      cartId: $cartID
      cartItems: [{ quantity: $quantity, sku: $sku, selected_options: $selected_options }]
    ) {
      cart {
        total_quantity
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_TO_CART_CONFIGURABLE = gql`
  mutation addConfigurableToCart(
    $cartID: String!
    $sku: String!
    $selected_options: [ID!]
    $qty: Float!
  ) {
    addProductsToCart(
      cartId: $cartID
      cartItems: [{ quantity: $qty, sku: $sku, selected_options: $selected_options }]
    ) {
      cart {
        total_quantity
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const GET_CART_DETAILS = gql`
  query cartDetails($id: String!) {
    cart(cart_id: $id) {
      total_quantity
      shipping_addresses {
        selected_shipping_method {
          amount {
            value
            currency
          }
        }
        customer_address_id
      }
      amasty_rewards_used_points {
        used_points
        used_points_to_inr
      }
      available_gift_wrappings {
        price {
          value
          currency
        }
        uid
      }
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }      
      reward_points_will_earn
      ${
        IsB2BLogged()
          ? `
        gst_will_earn
        display_volume_discount
        `
          : ``
      }
      items {
        uid
        prices {
          total_item_discount {
            value
          }
          price {
            value
            currency
          }
          discounts {
            amount {
              value
            }
          }
        }
        ${
          IsB2BLogged()
            ? `
          applied_tier_price {
            discount {
              amount_off
              percent_off
            }
            tier_price {
              value
            }
          }
          `
            : ``
        }

        ${
          IsB2BLogged()
            ? `
          applied_catalog_rule {
        discount {
          amount_off
          percent_off
        }
        catalog_price {
          currency
          value
        }
      }
          `
            : ``
        }
        product {
          image {
            label
          }
          __typename
          name
          sku
          free_product_available
          categories {
            name
            url_key
            url_path
          }
          offer_rules {
            discount
            type
            name
            show_on_cart
          }
          stock_status
          color
          brand_label
          product_quatity
          gst_tax
          thumbnail {
            sizes {
              image_id
              url
            }
            url
          }
          swatch_image
          price_range {
            maximum_price {
              regular_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
            }
          }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            configurable_product_option_value_uid
            value_label
            option_label
            swatch_data {
              type
              value
            }
          }
          configured_variant {
            sku
            sku
            name
            stock_status
            product_quatity
            thumbnail {
              url
            }
            image {
              url
              position
            }
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
        }
        quantity
        errors {
          code
          message
        }
      }
      free_shipping {
        amount
        status
        shipping_amount
      }
      applied_store_credit {
        applied_balance {
          currency
          value
        }
        current_balance {
          currency
          value
        }
      }
      applied_gb_wallet {
        applied_balance {
          currency
          value
        }
        current_balance {
          currency
          value
        }
      }
      prices {
        applied_promo_rules {
          label
          rule_id
          value
        }
        applied_coupon_code {
          total_amount
          applied_coupon_codes {
            code
            rule_id
            amount
          }
        }
        applied_coupon_code {
          total_amount
          applied_coupon_codes {
            code
            rule_id
            amount
          }
        }
        grand_total {
          value
          currency
        }
        discounts {
          amount {
            currency
            value
          }
        }
        subtotal_including_tax {
          currency
          value
        }
      }
      total_savings
      saveLater {
        uid
        qty
        selected_options {
          option_label
          value_label
          swatch_data {
            value
          }
        }
        selected_varient {
          uid
          id
          sku
          name
          stock_status
          product_quatity
          price_range {
            maximum_price {
              regular_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
            }
          }
        }
        product {
          image {
            label
          }
          name
          sku
          offer_rules {
            discount
            type
            name
            show_on_cart
          }
          gst_tax
          stock_status
          brand_label
          product_quatity
          thumbnail {
            url
          }
          price_range {
            maximum_price {
              regular_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
              final_price {
                value
                currency
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CART_ITEMS = gql`
  query ($id: String!) {
    cart(cart_id: $id) {
      items {
        uid
        product {
          __typename
          name
          sku
          stock_status
          color
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            configurable_product_option_value_uid
            value_label
            option_label
            swatch_data {
              type
              value
            }
          }
          configured_variant {
            sku
            name
            stock_status
            product_quatity
          }
        }
      }
    }
  }
`;
export const CART_STORE_CONFIG = gql`
  query {
    storeConfig {
      coupen_display_text
      free_shipping_subtotal
      free_shipping_enable
      razorpay_max_amount
      cash_on_delivery_max_amount
    }
  }
`;

export const CHECK_SERVICEABLE_PINCODE = gql`
  query ($pinCode: String!) {
    zipcode(code: $pinCode) {
      status
      country
      city
    }
  }
`;

export const GET_LOYALTY_POINTS = gql`
  query getLoyaltyPoints {
    getEasyRewardsStatistic {
      balance
      expired
      redeemed
      rewarded
    }
  }
`;

export const ADD_LOYALTY_POINTS = gql`
  mutation addLoyaltyPoints($cartID: String!, $loyaltyPoints: Float!) {
    useRewardPoints(input: { cart_id: $cartID, points: $loyaltyPoints }) {
      cart {
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const ADD_PROMOTIONAL_AMOUNT = gql`
  mutation applyStoreCreditToCart($cartID: String!) {
    applyStoreCreditToCart(input: { cart_id: $cartID }) {
      cart {
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const REMOVE_PROMOTIONAL_AMOUNT = gql`
  mutation removeStoreCreditFromCart($cartID: String!) {
    removeStoreCreditFromCart(input: { cart_id: $cartID }) {
      cart {
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const ADD_GB_WALLET = gql`
  mutation applyGBWalletToCart($cartID: String!) {
    applyGBWalletToCart(input: { cart_id: $cartID }) {
      cart {
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
    }
  }
`;

export const REMOVE_GB_WALLET = gql`
  mutation removeGBWalletFromCart($cartID: String!) {
    removeGBWalletFromCart(input: { cart_id: $cartID }) {
      cart {
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
    }
  }
`;

export const GET_GIFT_DETAILS = gql`
  query ($cartID: String!) {
    cart(cart_id: $cartID) {
      available_gift_wrappings {
        price {
          value
          currency
        }
        uid
      }
      prices {
        grand_total {
          value
          currency
        }
      }
    }
  }
`;

export const ADD_GIFT_OPTIONS = gql`
  mutation addGiftWarp($cartID: String!, $gift_uid: ID!) {
    setGiftOptionsOnCart(
      input: {
        printed_card_included: false
        gift_receipt_included: false
        cart_id: $cartID
        gift_wrapping_id: $gift_uid
        gift_message: { to: "unicommerce", from: "unicommerce", message: "Gift item" }
      }
    ) {
      cart {
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        reward_points_will_earn
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const REMOVE_GIFT_OPTIONS = gql`
  mutation ($cartID: String!) {
    setGiftOptionsOnCart(
      input: {
        printed_card_included: false
        gift_receipt_included: false
        cart_id: $cartID
        gift_wrapping_id: null
        gift_message: { to: "unicommerce", from: "unicommerce", message: "Gift item" }
      }
    ) {
      cart {
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const GET_BRAND = gql`
  query ($brand_name: String!) {
    getBrandsList(entity_id: $brand_name) {
      entity_id
      title
      status
      image
      description
      stores
      is_popular
      is_exclusive
      is_justin
      is_featured
    }
  }
`;

export const REMOVE_ITEM = gql`
  mutation ($cartID: String!, $productuid: ID!) {
    removeItemFromCart(input: { cart_id: $cartID, cart_item_uid: $productuid }) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            gst_tax
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
            sku
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation ($cartID: String!, $productuid: ID!, $qty: Float!) {
    updateCartItems(
      input: { cart_id: $cartID, cart_items: [{ cart_item_uid: $productuid, quantity: $qty }] }
    ) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        ${IsB2BLogged() ? `gst_will_earn` : ``}
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            gst_tax
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESS_TO_CART = gql`
  mutation setShippingAddressToCart(
    $cart_id: String!
    $shipping_addresses: [ShippingAddressInput]!
  ) {
    setShippingAddressesOnCart(
      input: { cart_id: $cart_id, shipping_addresses: $shipping_addresses }
    ) {
      cart {
        shipping_addresses {
          customer_address_id
        }
      }
    }
  }
`;

export const PUT_TO_WISHLIST = gql`
  mutation ($wishlistID: String!, $productsku: String!) {
    addProductsToWishlist(
      wishlistId: $wishlistID
      wishlistItems: [{ sku: $productsku, quantity: 1 }]
    ) {
      wishlist {
        id
        items_count
      }
    }
  }
`;
export const SET_BILLING_ADDRESS = gql`
  mutation ($cart_id: String!, $billing_address: BillingAddressInput!) {
    setBillingAddressOnCart(input: { cart_id: $cart_id, billing_address: $billing_address }) {
      cart {
        shipping_addresses {
          customer_address_id
        }
      }
    }
  }
`;

export const REMOVE_COUPON_FROM_CART = gql`
  mutation ($cartID: String!) {
    removeCouponFromCart(input: { cart_id: $cartID }) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const REMOVE_MULTIPLE_COUPONS_FROM_CART = gql`
  mutation ($cartID: String!, $couponCodes: [String!]!) {
    removeCouponsFromCart(input: { cart_id: $cartID, coupon_codes: $couponCodes }) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        ${IsB2BLogged() ? `gst_will_earn` : ``}
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const ADD_COUPON_TO_CART = gql`
  mutation ($cartID: String!, $couponCode: String!) {
    applyCouponToCart(input: { cart_id: $cartID, coupon_code: $couponCode }) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const ADD_MULTIPLE_COUPONS_TO_CART = gql`
  mutation ($cartID: String!, $couponCodes: [String!]!) {
    applyCouponsToCart(input: { cart_id: $cartID, coupon_codes: $couponCodes }) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        reward_points_will_earn
        ${IsB2BLogged() ? `gst_will_earn` : ``}
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            gst_tax
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
            sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
            label
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const GET_COUPONS = gql`
  query getCoupons($cartID: String!) {
    getCoupons(cart_id: $cartID) {
      action
      coupon
      is_mobile_specific
      description
      discount_amount
      from_date
      is_active
      name
      product_ids
      times_used
      to_date
      value
      valid_on_cart
      is_visible_in_list
    }
  }
`;

export const SET_SHIPPING_METHODS_ON_CART = gql`
  mutation SetShippingMethodsOnCart($input: SetShippingMethodsOnCartInput) {
    setShippingMethodsOnCart(input: $input) {
      cart {
        shipping_addresses {
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
            }
          }
        }
      }
    }
  }
`;

export const CHECK_PINCODE_SERVICEABILITY_QUERY = gql`
  query CheckPincodeServiceability($code: String!) {
    zipcode(code: $code) {
      status
      country
      city
      state
      country_id
      state_id
    }
  }
`;

export const UPDATE_SHIPPING_ADDRESS = gql`
  query ($id: String!) {
    cart(cart_id: $id) {
      total_quantity
      shipping_addresses {
        selected_shipping_method {
          amount {
            value
            currency
          }
        }
        customer_address_id
      }
      promotional_wallet_can_use
      applied_store_credit {
        applied_balance {
          currency
          value
        }
        current_balance {
          currency
          value
        }
      }
      ${IsB2BLogged() ? `gst_will_earn` : ``}
      applied_gb_wallet {
        applied_balance {
          currency
          value
        }
        current_balance {
          currency
          value
        }
      }
      prices {
        applied_promo_rules {
          label
          rule_id
          value
        }
        grand_total {
          value
          currency
        }
        discounts {
          amount {
            currency
            value
          }
        }
        subtotal_including_tax {
          currency
          value
        }
      }
      total_savings
    }
  }
`;

export const CHECK_CART_STOCK_STATUS = gql`
  query ($cart_id: String!) {
    cart(cart_id: $cart_id) {
      items {
        product {
          stock_status
        }
        ... on ConfigurableCartItem {
          configured_variant {
            stock_status
          }
        }
      }
    }
  }
`;

export const QUICK_BUY_BY_SKU_WITH_QTY = gql`
  mutation ($sku: String!, $qty: Float!) {
    quickBuyBySku(sku: $sku, qty: $qty) {
      cart {
        id
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const QUICK_BUY_BY_SKU = gql`
  mutation ($sku: String!) {
    quickBuyBySku(sku: $sku, qty: 1) {
      cart {
        id
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const RETRIVE_CART = gql`
  mutation ($cartID: String!) {
    retrivecartbyid(id: $cartID) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            ${IsB2BLogged() ? `gst_tax` : ``}
            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
            sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const ADD_MULTIPLE_TO_CART_SIMPLE = gql`
  mutation addSimpleProductToCart($cartID: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId: $cartID, cartItems: $cartItems) {
      cart {
        total_quantity
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const GET_FREE_PRODUCTS = gql`
  query getFreeProducts($id: String!) {
    getAvailableFreeGifts(cartId: $id) {
      available_gifts_qty
      allowed_qty
      free_items {
        cart_item_uid
        sku
        product {
          brand_label
          uid
          __typename
          name
          sku
          __typename
          thumbnail {
            url
            label
          }
          ... on ConfigurableProduct {
            configurable_options {
              label
              attribute_code
              values {
                label
                value_index
                swatch_data {
                  value
                  __typename
                }
              }
            }
            variants {
              product {
                brand_label
                name
                sku
                image {
                  label
                  url
                  __typename
                }
                swatch_image
                __typename
              }
              attributes {
                uid
                label
                code
                value_index
                __typename
              }
              __typename
            }
            __typename
          }
        }
      }
    }
  }
`;

export const ADD_FREE_ITEMS = gql`
  mutation amPromoAddFreeGiftsToCart($cartID: String!, $promoItems: [amPromoItemInput!]!) {
    amPromoAddFreeGiftsToCart(cartId: $cartID, promoItems: $promoItems) {
      cart {
        total_quantity
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            ${IsB2BLogged() ? `gst_tax` : ``}
            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const ADD_SAVE_FOR_LATER = gql`
  mutation addSaveForLater($cart_id: String!, $item_uid: String!, $child_sku: String) {
    addSaveForLater(cart_id: $cart_id, item_uid: $item_uid, child_sku: $child_sku) {
      response
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          uid
          price {
            value
            currency
          }
        }
        reward_points_will_earn
        ${IsB2BLogged() ? `gst_will_earn` : ``}
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            gst_tax
            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
            sku
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
        saveLater {
          uid
          qty
          selected_options {
            option_label
            value_label
            swatch_data {
              value
            }
          }
          selected_varient {
            uid
            id
            name
            stock_status
            product_quatity
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          product {
            name
            sku
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            gst_tax
            stock_status
            brand_label
            product_quatity
            thumbnail {
              url
            }
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
        }
        __typename
      }
    }
  }
`;

export const RETRIVE_TO_CART = gql`
  mutation retriveToCart($cart_id: String!, $saveLaterUid: String!) {
    retriveToCart(cart_id: $cart_id, saveLaterUid: $saveLaterUid) {
      response
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          uid
          price {
            value
            currency
          }
        }
        reward_points_will_earn
        ${IsB2BLogged() ? `gst_will_earn` : ``}
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            gst_tax
            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
        saveLater {
          uid
          qty
          selected_options {
            option_label
            value_label
            swatch_data {
              value
            }
          }
          selected_varient {
            uid
            id
            name
            stock_status
            product_quatity
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          product {
            name
            sku
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            gst_tax
            stock_status
            brand_label
            product_quatity
            thumbnail {
              url
            }
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
        }
        __typename
      }
    }
  }
`;

export const UPDATE_CART_FOR_OFFLINE = gql`
  mutation updateCartForOfflineCredit($cart_id: String!) {
    updateCartForOfflineCredit(cart_id: $cart_id) {
      response
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          uid
          price {
            value
            currency
          }
        }
        reward_points_will_earn
        gst_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            gst_tax
            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
        saveLater {
          uid
          qty
          selected_options {
            option_label
            value_label
            swatch_data {
              value
            }
          }
          selected_varient {
            uid
            id
            name
            stock_status
            product_quatity
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          product {
            name
            sku
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            gst_tax
            stock_status
            brand_label
            product_quatity
            thumbnail {
              url
            }
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
        }
        __typename
      }
    }
  }
`;

export const DELETE_SAVE_FOR_LATER = gql`
  mutation deleteSaveForLater($cart_id: String!, $saveLaterUid: String!) {
    deleteSaveForLater(cart_id: $cart_id, saveLaterUid: $saveLaterUid) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          uid
          price {
            value
            currency
          }
        }
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          ${
            IsB2BLogged()
              ? `
            applied_tier_price {
              discount {
                amount_off
                percent_off
              }
              tier_price {
                value
              }
            }
            `
              : ``
          }
          product {
            __typename
            name
            sku
            free_product_available
            categories {
              name
              url_key
              url_path
            }
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity

            thumbnail {
              sizes {
                image_id
                url
              }
              url
            }
            swatch_image
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
            gst_tax
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              sku
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        saveLater {
          uid
          qty
          selected_options {
            option_label
            value_label
            swatch_data {
              value
            }
          }
          selected_varient {
            uid
            id
            name
            stock_status
            product_quatity
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          product {
            name
            sku
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            brand_label
            product_quatity
            thumbnail {
              url
            }
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
            gst_tax
          }
        }
        __typename
      }
    }
  }
`;

export const UPDATE_MULTIPLE_CART_ITEM = gql`
  mutation ($cartID: String!, $cartItems: [CartItemUpdateInput!]!) {
    updateCartItems(input: { cart_id: $cartID, cart_items: $cartItems }) {
      cart {
        total_quantity
        shipping_addresses {
          selected_shipping_method {
            amount {
              value
              currency
            }
          }
        }
        amasty_rewards_used_points {
          used_points
          used_points_to_inr
        }
        available_gift_wrappings {
          price {
            value
            currency
          }
          uid
        }
        reward_points_will_earn
        gift_wrapping {
          price {
            value
            currency
          }
          design
          id
        }
        free_shipping {
          amount
          status
          shipping_amount
        }
        items {
          uid
          prices {
            total_item_discount {
              value
            }
            price {
              value
              currency
            }
            discounts {
              amount {
                value
              }
            }
          }
          product {
            __typename
            name
            sku
            free_product_available
            offer_rules {
              discount
              type
              name
              show_on_cart
            }
            stock_status
            color
            brand_label
            product_quatity
            image {
              url
              position
            }
            thumbnail {
              url
              position
            }
            swatch_image
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
          ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              configurable_product_option_value_uid
              value_label
              option_label
              swatch_data {
                type
                value
              }
            }
            configured_variant {
              name
              stock_status
              product_quatity
              thumbnail {
                url
              }
              image {
                url
                position
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
            }
          }
          quantity
          errors {
            code
            message
          }
        }
        reward_points_will_earn
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_store_credit {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        applied_gb_wallet {
          applied_balance {
            currency
            value
          }
          current_balance {
            currency
            value
          }
        }
        prices {
          applied_promo_rules {
            label
            rule_id
            value
          }
          applied_coupon_code {
            total_amount
            applied_coupon_codes {
              code
              rule_id
              amount
            }
          }
          grand_total {
            value
            currency
          }
          discounts {
            amount {
              currency
              value
            }
          }
          subtotal_including_tax {
            currency
            value
          }
        }
        total_savings
      }
    }
  }
`;

export const SET_EMAIL_ON_CART = gql`
  mutation setEmailOnCart($cartID: String!, $email: String!) {
    setGuestEmailOnCart(input: { cart_id: $cartID, email: $email }) {
      cart {
        email
      }
    }
  }
`;

export const SEND_OTP_VERIFY = gql`
  mutation ($phone: String!) {
    sendCheckoutOTP(mobileNumber: $phone, websiteId: 1) {
      status
      message
    }
  }
`;

export const CHECKOUT_OTP_VERIFY = gql`
  query ($phone: String!, $otp: String!) {
    checkoutOTPVerify(mobileNumber: $phone, otp: $otp, websiteId: 1) {
      status
      message
    }
  }
`;

export const GET_BILLING_ADDRESS = gql`
  query cartDetails($id: String!) {
    cart(cart_id: $id) {
      email
      billing_address {
        firstname
        lastname
        telephone
        city
        postcode
        street
        region {
          code
          label
          region_id
        }
      }
    }
  }
`;
