/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetAddress } from 'Features/address/addressSlice';
import {
  createEmptyCart,
  getCustomerCartCount,
  getCustomerCartId,
  assignCustomerCart,
  resetCart,
  setCartID,
} from 'Features/cart/cartSlice';
import { getWishlistID, resetWishlist } from 'Features/wishlist/wishlistSlice';
import { getAllStoreConfig, getCustomerTypeCheck, getTimerConfig } from 'Features/storeConfig';
import { IsB2BEnable } from 'B2B/Utils/IsB2B';
import { IsToken } from 'Pages/Page/utils';
import { setIsLoading } from '.';

export const resetAllOnLogout = createAsyncThunk(
  'global/resetAllOnLogout',
  async (_, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem('token');
    try {
      if (!token) {
        dispatch(resetCart());
        dispatch(resetWishlist());
        dispatch(resetAddress());
        await dispatch(createEmptyCart());
        await dispatch(getCustomerCartCount());
        if (localStorage.getItem('isB2B')) {
          window.location.href = '/business';
        } else {
          window.location.href = '/';
        }
      }
    } catch (error) {
      rejectWithValue(error.message);
    }
  },
);

export const dispatchActionsOnFirstLoad = createAsyncThunk(
  'global/dispatchActionsOnFirstLoad',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const reduxCartID = getState().cart.cartID;
    const token = localStorage.getItem('token');
    const cartID = localStorage.getItem('cartID');
    try {
      if (IsB2BEnable()) {
        if (IsToken()) {
          await dispatch(getCustomerTypeCheck());
        } else {
          localStorage.removeItem('isB2B');
        }
      }
      dispatch(getAllStoreConfig());
      dispatch(getTimerConfig());
      if (cartID && !token) {
        // set the loading for the fetching data.
        dispatch(setIsLoading(true));
        try {
          dispatch(setCartID(cartID));
          dispatch(getCustomerCartCount()).unwrap();
          dispatch(setIsLoading(false));
        } catch (error) {
          await dispatch(createEmptyCart());
          dispatch(setIsLoading(false));
        }
      }

      if (!cartID && !token) {
        dispatch(setIsLoading(true));
        try {
          await dispatch(createEmptyCart());
          Promise.all([dispatch(getCustomerCartCount())]).catch((error) => {
            dispatch(setIsLoading(false));
            throw new Error(error);
          });

          dispatch(setIsLoading(false));
        } catch (error) {
          dispatch(setIsLoading(false));
          throw new Error(error);
        }
      }

      if (
        cartID &&
        token &&
        cartID.toString() === reduxCartID &&
        parseInt(getState().cart?.cartCount) >= 1
      ) {
        dispatch(setIsLoading(true));
        try {
          await dispatch(assignCustomerCart()).unwrap();
          await dispatch(getCustomerCartId()).unwrap();
          await dispatch(getWishlistID()).unwrap();
          if (reduxCartID.toString() !== getState().cart?.cartID.toString()) {
            localStorage.removeItem('cartID');
          }

          Promise.allSettled([dispatch(getCustomerCartCount()).unwrap()])
            .then((results) => {
              dispatch(setIsLoading(false));
              results.forEach((result, index) => {
                if (result.status === 'rejected') {
                  dispatch(setIsLoading(false));
                  console.error(`Promise ${index} rejected with reason:`, result.reason);
                }
              });
            })
            .catch((error) => {
              dispatch(setIsLoading(false));
              console.error('Promise.allSettled error:', error);
            });
        } catch (error) {
          throw new Error(error);
        }
      } else if (token) {
        dispatch(setIsLoading(true));
        try {
          await dispatch(getCustomerCartId()).unwrap();
          await dispatch(getWishlistID()).unwrap();
        } catch (error) {
          dispatch(setIsLoading(false));
          throw new Error(error);
        }

        Promise.allSettled([dispatch(getCustomerCartCount()).unwrap()])
          .then((results) => {
            dispatch(setIsLoading(false));
            results.forEach((result, index) => {
              if (result.status === 'rejected') {
                dispatch(setIsLoading(false));
                console.error(`Promise ${index} rejected with reason:`, result.reason);
              }
            });
          })
          .catch((error) => {
            dispatch(setIsLoading(false));
            console.error('Promise.allSettled error:', error);
          });
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      rejectWithValue(error.message);
    }
  },
);
