import { useEffect, useRef, useState } from 'react';
import gbLogo from 'B2B/Assets/Icons/G-logo.svg';
import close from 'B2B/Assets/Icons/close.svg';
import cartLeft from 'B2B/Assets/Icons/caretLeft.svg';
import { useDispatch, useSelector } from 'react-redux';
import { changeB2BTrigger } from 'Features/login';
import SignIn from '../SignIn/SignIn';
import GST from '../GST/GST';
import GSTVerification from '../GSTVerification/GSTVerification';
import GSTCertificate from '../GSTCertificate/GSTCertificate';
import SignInOTPVerification from '../SignInOTPVerification/SignInOTPVerification';
import './Form.css';

const Form = ({ hasLoggedIn, openModal = null, setOpenModal = false }) => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const loginTrigger = useSelector((state) => state.login.b2bTrigger);

  const formRef = useRef(null);

  useEffect(() => {
    if (openModal !== null) {
      setIsLoginOpen(openModal);
    } else {
      setIsLoginOpen(loginTrigger);
    }
  }, [loginTrigger, openModal, setIsLoginOpen]);

  const b2bSignInState = useSelector((state) => state.b2bSignIn);

  const dispatch = useDispatch();

  const formType = { SignIn, SignInOTPVerification, GST, GSTVerification, GSTCertificate };

  const [currentForm, setCurrentForm] = useState(hasLoggedIn ? 'GST' : 'SignIn');
  const [showBackBtn, setShowbackBtn] = useState(true);

  useEffect(() => {
    if (currentForm === 'SignIn') {
      setShowbackBtn(false);
    } else if (hasLoggedIn && currentForm === 'GST') {
      setShowbackBtn(false);
    } else {
      setShowbackBtn(true);
    }
  }, [currentForm, hasLoggedIn]);

  useEffect(() => {
    if (isLoginOpen) {
      document.querySelector('html').classList.add('disable-scroll');
    } else {
      document.querySelector('html').classList.remove('disable-scroll');
    }

    const elements = document.getElementsByClassName('form-icon');

    if (elements?.length > 0 && window.innerWidth < 991) {
      const element = elements[0];
      const react = element.getBoundingClientRect();
      const computedWidth = react.width;

      element.style.top = `-${computedWidth / 3}px`;
      element.style.left = `calc(50% - ${computedWidth / 3}px)`;
    }
  }, [isLoginOpen]);

  const updateFormType = (type) => {
    if (type) {
      setCurrentForm(type);
    } else {
      if (currentForm === 'SignInOTPVerification') {
        setCurrentForm('SignIn');
      } else if (currentForm === 'GST') {
        setCurrentForm('SignInOTPVerification');
      }

      if (b2bSignInState?.businessType) {
        const businessType = b2bSignInState?.businessType;

        if (businessType === 'pan_card') {
          if (currentForm === 'GSTVerification') {
            setCurrentForm('GSTCertificate');
          } else if (currentForm === 'GSTCertificate') {
            setCurrentForm('GST');
          }
        } else if (businessType === 'gstin') {
          if (currentForm === 'GSTVerification') {
            setCurrentForm('GST');
          } else if (currentForm === 'GSTCertificate') {
            setCurrentForm('GSTVerification');
          }
        }
      }
    }
  };

  const closeButtonHandler = () => {
    if (setOpenModal) {
      setOpenModal(false);
    }

    dispatch(changeB2BTrigger(false));
  };

  const handleClickOutside = (event) => {
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      return;
    }

    if (formRef.current && !formRef.current.contains(event.target)) {
      closeButtonHandler();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderCurrentForm = () => {
    const CurrentFormComponent = formType[currentForm];
    return (
      <CurrentFormComponent
        updateFormType={updateFormType}
        closeButtonHandler={closeButtonHandler}
        hasLoggedIn={hasLoggedIn}
      />
    );
  };

  return (
    <div ref={formRef} className={`form ${isLoginOpen && 'active'}`}>
      {isLoginOpen && (
        <>
          <div className='form-top'>
            {showBackBtn && (
              <img
                className='form-back'
                src={cartLeft}
                tabIndex={0}
                role='button'
                onClick={() => updateFormType()}
                alt='backBtn'
              />
            )}
            <div className='form-img'>
              <b>Welcome to</b>
              Glamourbook business
            </div>
            <img
              className='form-close'
              src={close}
              tabIndex={0}
              role='button'
              onClick={() => closeButtonHandler()}
              alt='closeIcon'
            />
            <img className='form-icon' src={gbLogo} alt='gbLogo' />
          </div>
          <div className='form-bottom'>
            <div className='form-heading'>
              {hasLoggedIn && currentForm === 'GST'
                ? 'Your GST is not registered with us. To Avail GST input credit please add your GSTIN Number'
                : 'Your one-stop destination for all things beauty and personal care'}
            </div>
            {renderCurrentForm()}
          </div>
        </>
      )}
    </div>
  );
};

export default Form;
