import * as Yup from 'yup';

export const SignInSchema = Yup.object({
  mobileNumber: Yup.string()
    .matches(/^[5-9]\d{9,}$/, 'Please enter a valid mobile number')
    .required('Please enter mobile number'),
  tnc: Yup.boolean().oneOf([true], 'Please check this'),
});

export const GSTSchema = Yup.object({
  email: Yup.string().when('hasLoggedIn', {
    is: true,
    then: () => Yup.string().notRequired(),
    otherwise: () =>
      Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'Please enter a valid Email ID',
        )
        .required('Please enter a Email ID'),
  }),
  businessOption: Yup.string().required(),
  gstin: Yup.string().when('businessOption', {
    is: 'gstin',
    then: () =>
      Yup.string()
        .matches(/^[a-zA-Z0-9]{15}$/, 'Please enter a valid 15-digit GSTIN')
        .required('Please enter a 15-digit GSTIN'),
    otherwise: () => Yup.string().notRequired(),
  }),
  entityTypeDd: Yup.string().notOneOf(['Select'], 'Please select the Entity Type'),
  entityTypeInput: Yup.string().when('entityTypeDd', {
    is: 'Others',
    then: () => Yup.string().required('Please enter the Entity Type'),
    otherwise: () => Yup.string().notRequired(),
  }),
});
