/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import { Play } from 'phosphor-react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { LOC_IMG, VIDEO, YT_VIDEO, VIMEO_VIDEO } from 'Utils/Strings';
import Skeleton from './Skeleton';
import ImgFallback from './ImgFallback';
import './Media.css';

/**
 *
 * @param props
 */

let isModalOpen;

function Media(props) {
  useEffect(() => {
    isModalOpen = false;
    return () => {
      document.body.style.position = '';
    };
  }, []);

  const { src, alt, className, style, ...other } = props;
  const [showPlayer, setShowPlayer] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showThumbanil, setShowThumbnail] = useState(true);
  const videoRef = useRef(null);

  function preventScroll(event) {
    if (isModalOpen) {
      event.preventDefault();
    }
  }

  const handleClose = () => {
    document.body.style.position = 'unset';
    isModalOpen = false;
    window.removeEventListener('wheel', preventScroll);
    window.removeEventListener('touchmove', preventScroll);
    setIsPlaying(false);
    setShowPlayer(false);
  };

  const handleShow = () => {
    document.body.style.position = 'absolute';
    isModalOpen = true;
    window.addEventListener('touchmove', preventScroll, { passive: false });
    window.addEventListener('wheel', preventScroll, { passive: false });
    setIsPlaying(true);
    setShowPlayer(true);
  };

  const handlePlay = () => {
    if (videoRef.current) {
      setShowThumbnail(false);
      videoRef.current.play();
    }
  };

  if (!src || src === '') return <ImgFallback className={className} style={style} />;

  function getVimeoVideoId(vimeoUrl) {
    const match = vimeoUrl.match(/vimeo\.com\/(\d+)/i);

    if (match && match[1]) {
      return match[1];
    }
    // Return an empty string if no match is found
    return '';
  }
  return (
    <>
      {(() => {
        switch (props.resource_type) {
          case LOC_IMG:
            return (
              <LazyLoadImage
                src={src}
                alt={alt}
                className={className}
                style={style}
                visibleByDefault
                placeholder={<Skeleton className='w-100 h-100' />}
                {...other}
              />
            );
          case VIDEO:
            return props.video_type === YT_VIDEO ? (
              <div className='videosContainer'>
                {props.resource_path_poster ? (
                  <LazyLoadImage
                    className='img-fluid h-100'
                    visibleByDefault
                    src={props.resource_path_poster}
                    alt={alt}
                  />
                ) : (
                  <ImgFallback className={className} style={style} />
                )}

                <button
                  className='ytPlayBtn'
                  onClick={() => window.open(src.replace('embed', 'watch'), '_blank')}
                  type='button'
                >
                  <Play size={52} weight='fill' color='#fff' className='playbtn' />
                </button>
              </div>
            ) : props.video_type === VIMEO_VIDEO ? (
              <div className='videosContainer'>
                {props.resource_path_poster ? (
                  <LazyLoadImage
                    className='w-100'
                    visibleByDefault
                    src={props.resource_path_poster}
                    alt={alt}
                  />
                ) : (
                  <ImgFallback className={className} style={style} />
                )}
                <button
                  className='border-0 w-100 h-100'
                  onClick={handleShow}
                  type='button'
                  id='playBtn'
                >
                  <Play size={52} weight='fill' color='#fff' className='playbtn' />
                </button>
                <Modal
                  size='xl'
                  className='mediaPlayer'
                  centered
                  show={showPlayer}
                  onHide={handleClose}
                >
                  <Modal.Body style={{ padding: '4px' }}>
                    <iframe
                      title='Vimeo Video'
                      src={`https://player.vimeo.com/video/${getVimeoVideoId(
                        src,
                      )}?autoplay=1&title=0&byline=0&portrait=0`}
                      className={className}
                      style={{ ...style, height: ` 100% ` }}
                      allow=' autoplay; fullscreen; picture-in-picture'
                      allowFullScreen
                    />
                    <script src='https://player.vimeo.com/api/player.js' />
                  </Modal.Body>
                </Modal>
              </div>
            ) : props.page === 'homeOffers' ||
              props.page === 'offerHighlights' ||
              props.page === 'exclusives' ||
              props.page === 'exclusives1' ||
              props.page === 'landing_carousel' ? (
              <div
                className={`videosContainer ${
                  props.page === 'homeOffers'
                    ? 'videosContainer-mobile-homeOffers'
                    : props.page === 'exclusives'
                    ? 'videosContainer-mobile-exclusives'
                    : props.page === 'exclusives1'
                    ? 'videosContainer-mobile-exclusives mob-small-exclu'
                    : ''
                }`}
                style={{
                  width: `${props.page === 'offerHighlights' && '98%'}`,
                  margin: `${props.page === 'offerHighlights' && '0 auto'}`,
                }}
              >
                <video
                  className={className}
                  style={{
                    ...style,
                    height: ` 100% `,
                  }}
                  autoPlay={props.page !== 'look'}
                  loop='loop'
                  playsInline
                  muted
                >
                  <source src={src} type={`video/${src.split('.')[src.split('.').length - 1]}`} />
                </video>
              </div>
            ) : props.page === 'look' ? (
              <div className='videosContainer rounded-0'>
                {showThumbanil && (
                  <>
                    <LazyLoadImage
                      className='w-100 position-absolute'
                      visibleByDefault
                      src={props.resource_path_poster}
                      alt={alt}
                      style={{ zIndex: 2 }}
                    />
                    <button
                      className='border-0 w-100 h-100'
                      onClick={handlePlay}
                      type='button'
                      id='playBtn'
                    >
                      <Play size={80} weight='fill' color='#fff' className='playbtn' />
                    </button>
                  </>
                )}
                <video
                  className={className}
                  style={{
                    ...style,
                    height: ` 100% `,
                  }}
                  loop='loop'
                  playsInline
                  muted
                  controls
                  ref={videoRef}
                >
                  <source src={src} type={`video/${src.split('.')[src.split('.').length - 1]}`} />
                </video>
              </div>
            ) : (
              <div className='videosContainer'>
                {props.resource_path_poster ? (
                  <LazyLoadImage
                    className='w-100'
                    visibleByDefault
                    src={props.resource_path_poster}
                    alt={alt}
                  />
                ) : (
                  <ImgFallback className={className} style={style} />
                )}

                <button
                  className='border-0 w-100 h-100'
                  onClick={handleShow}
                  type='button'
                  id='playBtn'
                >
                  <Play size={52} weight='fill' color='#fff' className='playbtn' />
                </button>

                <Modal
                  size='xl'
                  className='mediaPlayer'
                  centered
                  show={showPlayer}
                  onHide={handleClose}
                >
                  <Modal.Body style={{ padding: '4px' }}>
                    <video className={className} style={style} controls autoPlay={isPlaying}>
                      <source
                        src={src}
                        type={`video/${src.split('.')[src.split('.').length - 1]}`}
                      />
                    </video>
                  </Modal.Body>
                </Modal>
              </div>
            );
          default:
            return (
              <LazyLoadImage
                visibleByDefault
                src={src}
                alt={alt}
                className={className}
                style={style}
                placeholder={<Skeleton className='w-100 h-100' />}
                {...other}
              />
            );
        }
      })()}
    </>
  );
}

Media.propTypes = {
  props: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
      className: PropTypes.string,
      style: PropTypes.object,
    }),
  ).isRequired,
};

export default Media;
