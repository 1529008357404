import axios from 'axios';
import { IsB2BLogged } from 'B2B/Utils/IsB2B';
import { useSelector } from 'react-redux';

const useSearchResultData = () => {
  const magentoCustomerGroupState = useSelector(
    (state) => state.storeConfig.magento_customer_group,
  );

  const getSearchResultData = async (value) => {
    const res = await axios({
      url: 'https://commerce.adobe.io/search/graphql',
      method: 'post',
      headers: {
        'Magento-Environment-Id': process.env.REACT_APP_ENV_ID,
        'Magento-Website-Code': 'base',
        'Magento-Store-Code': 'main_website_store',
        'Magento-Store-View-Code': 'default',
        'X-Api-Key': 'search_gql',
      },
      data: {
        query: `{
            productSearch(phrase:"${decodeURIComponent(value)}" page_size: 5 ${
          IsB2BLogged() ? `context: { customerGroup: "${magentoCustomerGroupState}" }` : ''
        }) {
              total_count
              items {
                product {
                  name
                  sku
                  __typename
                }
              }
              ${!IsB2BLogged() ? 'suggestions' : ''}
            }
          }`,
      },
    });
    return res;
  };

  return getSearchResultData;
};

export default useSearchResultData;
