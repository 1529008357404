/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  trigger: false,
  b2bTrigger: false,
  formType: 'login',
  username: {
    type: 'email',
    field: '',
  },
  codes: null,
};
const LoginSlice = createSlice({
  name: 'name',
  initialState,
  reducers: {
    changeFormType: (state, { payload }) => {
      state.formType = payload;
    },
    updateusername: (state, { payload }) => {
      state.username = {
        field: payload.field,
        type: payload.type,
      };
    },
    addCodes: (state, { payload }) => {
      state.codes = payload;
    },
    changeTrigger: (state, { payload }) => {
      state.trigger = payload ? false : !state.trigger;
    },
    changeB2BTrigger: (state, { payload }) => {
      state.b2bTrigger = payload ? !!payload : false;
    },
    openLoginModal: (state, { payload }) => {
      state.trigger = payload;
    },
  },
});

export const {
  changeFormType,
  addCodes,
  updateusername,
  changeTrigger,
  changeB2BTrigger,
  openLoginModal,
} = LoginSlice.actions;
export default LoginSlice.reducer;
