import { useEffect, useState } from 'react';
import { CaretDown } from 'phosphor-react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNewCompany, setMobileNumber } from 'B2B/Redux/SignInSignUp/SignIn';
import { SignInSchema } from 'B2B/Utils/ValidationSchema';
import { CHECK_COMPANY_USER_EXISTS, LOGIN_OTP, CREATE_ACCOUNT_OTP } from 'B2B/Gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import './Signin.css';
import { Link } from 'react-router-dom';
import { changeB2BTrigger } from 'Features/login';

function SignIn({ updateFormType }) {
  const dispatch = useDispatch();
  const mobileNumberState = useSelector((state) => state.b2bSignIn.mobileNumber);

  const [isNextButtonDisable, setIsNextButtonDisable] = useState(false);
  const [checkCustomerUserExistsQuery] = useLazyQuery(CHECK_COMPANY_USER_EXISTS);
  const [loginOTPMutation] = useMutation(LOGIN_OTP);
  const [createAccountOTPMutation] = useMutation(CREATE_ACCOUNT_OTP);

  const formik = useFormik({
    initialValues: {
      mobileNumber: mobileNumberState,
      tnc: false,
    },
    validationSchema: SignInSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await checkCustomerUserExistsQuery({
          variables: {
            field_value: `91${values.mobileNumber}`,
            type: 'mobile',
          },
        }).then(async (res) => {
          if (res?.data?.checkCompanyUserExists?.status) {
            dispatch(setIsNewCompany(false));
            if (res?.data?.checkCompanyUserExists?.type === 'b2b') {
              await loginOTPMutation({
                variables: {
                  mobileNumber: `91${values.mobileNumber}`,
                  websiteId: 1,
                },
              }).then((res) => {
                if (res?.data?.loginOTP?.status) {
                  dispatch(setMobileNumber(values.mobileNumber));
                  updateFormType('SignInOTPVerification');
                }
              });
            } else {
              setFieldError(
                'mobileNumber',
                'This number is already registered on glamourbook.com, kindly try with new one.',
              );
            }
          } else {
            dispatch(setIsNewCompany(true));
            await createAccountOTPMutation({
              variables: {
                mobileNumber: `91${values.mobileNumber}`,
                websiteId: 1,
              },
            }).then((res) => {
              if (res?.data?.createAccountOTP?.status) {
                dispatch(setMobileNumber(values.mobileNumber));
                updateFormType('SignInOTPVerification');
              }
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, isSubmitting } = formik;

  useEffect(() => {
    if ((errors.mobileNumber && touched.mobileNumber) || (errors.tnc && touched.tnc)) {
      setIsNextButtonDisable(true);
    } else {
      setIsNextButtonDisable(false);
    }
  }, [errors, touched]);

  return (
    <div className='signin'>
      <form onSubmit={handleSubmit}>
        <div className='signin-phone'>Mobile Number*</div>
        <div
          className={`phoneInput ${
            touched.mobileNumber && errors.mobileNumber ? 'errorShake' : ''
          }`}
        >
          <div className='phoneInput-CountryCode'>
            +91
            <CaretDown size={16} weight='bold' color='#d9d9d9' />
          </div>
          <input
            placeholder='Enter Mobile Number'
            value={values.mobileNumber}
            maxLength={10}
            onChange={handleChange}
            onBlur={handleBlur}
            name='mobileNumber'
          />
        </div>
        {touched.mobileNumber && errors.mobileNumber && (
          <div className='validationError'>{errors.mobileNumber}</div>
        )}
        <div className={`signin-tnc ${touched.tnc && errors.tnc ? 'errorShake' : ''}`}>
          <input
            type='checkbox'
            name='tnc'
            checked={values.tnc}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div>
            I agree to GlamourBook's {` `}
            <Link
              onClick={() => dispatch(changeB2BTrigger(false))}
              to='/business/b2btermsandconditions'
            >
              Terms & Conditions
            </Link>
            {` `}and{` `}
            <Link
              onClick={() => dispatch(changeB2BTrigger(false))}
              to='/business/b2bprivacypolicies'
            >
              Privacy Policy
            </Link>
            .
          </div>
        </div>
        {touched.tnc && errors.tnc && <div className='validationError'>{errors.tnc}</div>}
        <div className='signin-btn'>
          <button
            className='signin-continue whiteCta'
            disabled={isSubmitting || isNextButtonDisable}
            type='submit'
          >
            CONTINUE
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignIn;
