/* eslint-disable import/no-cycle */
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CREATE_COMPANY } from 'B2B/Gql';
import './GSTVerification.css';
import { GENERATE_CUSTOMER_TOKEN_MOBILE } from 'Gql/query';

function GSTVerification({ updateFormType }) {
  const b2bSignInState = useSelector((state) => state.b2bSignIn);
  const storeConfigState = useSelector((state) => state.storeConfig);

  const [createCompanyMutation] = useMutation(CREATE_COMPANY);
  const [GenerateCustomerTokenMobile] = useMutation(GENERATE_CUSTOMER_TOKEN_MOBILE);

  const formikProps = {
    initialValues: {
      gstin: b2bSignInState.gstin,
      panCardNumber: b2bSignInState.panCardNumber,
      entityName: b2bSignInState.entityName,
      bussinessAddress: b2bSignInState.principalAddress,
      userType: b2bSignInState.userType,
    },
  };

  const onConfirmHandler = async () => {
    if (b2bSignInState?.businessType === 'pan_card') {
      const createCompanyResponse = await createCompanyMutation({
        variables: {
          company_name: b2bSignInState?.entityName,
          firstname: b2bSignInState?.entityName?.split(' ')?.[0]?.replace(/[^a-zA-Z0-9]/g, ''),
          lastname:
            b2bSignInState?.entityName
              ?.split(' ')
              ?.slice(1)
              ?.join(' ')
              ?.replace(/[^a-zA-Z0-9 ]/g, '') || ' ',
          company_email: b2bSignInState?.email,
          mobilenumber: `91${b2bSignInState?.mobileNumber}`,
          password: 'password',
          street: storeConfigState?.general_store_information_street1,
          city: storeConfigState?.general_store_information_city,
          pincode: storeConfigState?.general_store_information_postcode,
          region_code: 'KA',
          country_id: storeConfigState?.general_store_information_country_id,
          business_details_type: b2bSignInState?.businessType,
          pan_card: b2bSignInState?.panCardFile,
          business_address: b2bSignInState?.principalAddress,
          entity_name: b2bSignInState?.entityName,
          date_of_issue: b2bSignInState?.panDateOfIssue,
          pan_number: b2bSignInState?.panCardNumber,
          type_of_holder: b2bSignInState?.userType,
          customer_business_entity: b2bSignInState?.entityType,
        },
      });

      if (createCompanyResponse?.data?.createCompany) {
        const generateCustomerTokenMobileResponse = await GenerateCustomerTokenMobile({
          variables: {
            mobile: `91${b2bSignInState?.mobileNumber}`,
            password: 'password',
          },
        });
        if (generateCustomerTokenMobileResponse?.data?.generateCustomerTokenMobile) {
          localStorage.setItem(
            'token',
            generateCustomerTokenMobileResponse?.data?.generateCustomerTokenMobile?.token,
          );
          localStorage.setItem('isB2B', true);
          localStorage.setItem('loggedPopUpB2B', true);
          window.location.href = '/business';
        } else {
          throw new Error(generateCustomerTokenMobileResponse?.errors?.[0]?.message);
        }
      } else {
        throw new Error(createCompanyResponse?.errors?.[0]?.message);
      }
    } else if (b2bSignInState?.businessType === 'gstin') {
      updateFormType('GSTCertificate');
    }
  };

  const { values } = useFormik(formikProps);

  return (
    <div className='gstVerification'>
      <div className='gstVerification-inputHeader'>
        <div className='gstVerifcation-inputText'>{`${
          b2bSignInState?.businessType === 'pan_card' ? 'PAN Number' : 'GSTIN'
        }`}</div>
        <input
          placeholder='Enter Email ID'
          disabled
          name='gstin'
          value={b2bSignInState?.businessType === 'pan_card' ? values.panCardNumber : values.gstin}
        />
      </div>
      <div className='gstVerification-inputHeader'>
        <div className='gstVerifcation-inputText'>{`${
          values.userType === 'Business' ? 'Entity Name' : 'Name'
        }`}</div>
        <input
          placeholder='Enter Entity Name'
          disabled
          name='entityName'
          value={values.entityName}
        />
      </div>
      <div className='gstVerification-inputHeader'>
        <div className='gstVerifcation-inputText'>{`${
          b2bSignInState?.businessType === 'pan_card' ? 'User Type' : 'Business Address'
        }`}</div>
        {b2bSignInState?.businessType === 'pan_card' ? (
          <input placeholder='Enter User Type' disabled name='gstin' value={values.userType} />
        ) : (
          <div placeholder='Enter Business Address' disabled name='bussinessAddress'>
            {values.bussinessAddress}
          </div>
        )}
      </div>
      <div className='gstVerification-btn'>
        <button
          className='gstVerification-submit whiteCta'
          onClick={() => onConfirmHandler()}
          tabIndex={0}
          type='button'
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

export default GSTVerification;
