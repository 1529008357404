/* eslint-disable import/no-cycle */
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { VIRTUAL_CART_ITEM } from 'Utils/Strings';
import DropdownProducts from './DropdownProducts';

function CartDropdown({ cart, prices, cartCount }) {
  const navigate = useNavigate();
  return cart.length === 0
    ? null
    : cart?.every((item) => item.__typename !== VIRTUAL_CART_ITEM) && (
        <div className='cartDropdown'>
          <div className='cart-pop-over'>
            <div className='cart-pop-over-arrow' />
            <div className='cart-pop-over-arrow-select-aria' />
          </div>
          <h3>CART</h3>

          <div className='cartDropdownProductWrapper'>
            {cart.map((prod) => (
              <DropdownProducts key={prod.uid} product={prod} cart={cart} />
            ))}
          </div>

          <div className='dropdownFooter'>
            <div>
              <span>
                Subtotal ({cartCount} {cartCount <= 1 ? 'Item' : 'Items'})
              </span>
              <span>
                {new Intl.NumberFormat('en-us', {
                  style: 'currency',
                  currency: prices?.subtotal_including_tax.currency || 'INR',
                  maximumFractionDigits: 0,
                })
                  .format(prices?.grand_total?.value)
                  .replace(/^(\D+)/, '$1 ')}
              </span>
            </div>

            <button type='button' className='whiteCta' onClick={() => navigate('/shopping-cart')}>
              view bag & checkout
            </button>
          </div>
        </div>
      );
}

const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  prices: state.cart.priceDetails,
  bagDiscount: state.cart.bagDiscount,
  cartCount: state.cart.cartCount,
});

export default connect(mapStateToProps)(CartDropdown);
