/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'Utils/Toast';
// import CryptoJS from 'crypto-js';
import { client } from 'Gql';
import { GET_REVIEW_METADATA } from 'Gql/Profile/order';
import {
  dispatchActionsOnFirstLoad,
  resetAllOnLogout,
  // getCacheStore,
} from './globalAction';

export const getFooterSEOVars = createAsyncThunk(
  'cart/getFooterSEOVars',
  async (args, { rejectWithValue }) => {
    try {
      return args;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getReviewMetadata = createAsyncThunk(
  'global/getReviewMetadata',
  async (_, { rejectWithValue }) => {
    try {
      const reviewMeta = await client.query({
        query: GET_REVIEW_METADATA,
      });
      return reviewMeta.data;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    loading: false,
    message: '',
    status: '',
    seoFooter: {},
    reviewMeta: [],
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(dispatchActionsOnFirstLoad.rejected, (state, { payload }) => {
        state.message = payload;
        state.status = 'rejected';
        notification.error(payload);
      })
      .addCase(dispatchActionsOnFirstLoad.pending, (state) => {
        state.message = 'Hold on a movement';
        state.status = 'pending';
      })
      .addCase(dispatchActionsOnFirstLoad.fulfilled, (state, { payload }) => {
        state.message = payload;
        state.status = 'success';
      })
      .addCase(resetAllOnLogout.pending, (state) => {
        state.loading = true;
        state.message = 'Hold on a movement';
        state.status = 'pending';
      })
      .addCase(resetAllOnLogout.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.status = 'rejected';
      })
      .addCase(resetAllOnLogout.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.status = 'success';
      })
      .addCase(getFooterSEOVars.pending, (state) => {
        state.loading = true;
        state.message = 'Hold on a movement';
        state.status = 'pending';
      })
      .addCase(getFooterSEOVars.rejected, (state, { payload }) => {
        state.loading = false;
        state.message = payload;
        state.status = 'rejected';
      })
      .addCase(getFooterSEOVars.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.seoFooter = payload;
        state.status = 'success';
      })
      .addCase(getReviewMetadata.fulfilled, (state, { payload }) => {
        state.reviewMeta = payload.productReviewRatingsMetadata.items;
        state.status = 'success';
      });
  },
});
export const { setIsLoading } = globalSlice.actions;
export const getGlobalLoading = (state) => state.global.loading;
export default globalSlice.reducer;
